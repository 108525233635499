<template>
  <div class="article_list_box">
    <div class="img_title">
      <div style="width: 1200px; margin: 0 auto; position: relative">
        <span class="box_title">
          {{ Data.name ? Data.name : columnName }}
        </span>
      </div>
      <img
        style="width: 100%; height: 100%"
        src="../../assets/img/title_1.jpg"
        alt=""
      />
    </div>
    <div class="article_list">
      <div class="article_list_right">
        <div class="article_list_right_top">
          <div class="article_list_right_title1">详情正文</div>
          <div class="article_list_right_tab">
            首页 >
            {{ Data.parentName ? Data.parentName : "" }}
            {{ Data.parentName ? `>${Data.name}` : columnName }}
          </div>
        </div>
        <div>
          <p style="color: #555555; margin: 20px">
            查询说明：输入您要查询的居间人全称或公司简称。
          </p>
          <!-- <div style="background:rgb(220, 230, 241);width:100%;padding:5px 0;"> -->
          <div class="search_twig">
            <el-input
              class="asasas"
              v-model="search_input"
              @focus="input_focus"
              @blur="input_blur"
              placeholder="请输入您要查询的居间人全称或公司简称。"
            ></el-input>
            <div class="search_button" @click="handle_search">搜索</div>
          </div>
          <!-- </div> -->
          <el-table
            v-if="is"
            border
            class="my_table"
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            :header-cell-style="{
              background: '#f8f8f9',
              color: '#515a6e',
              'font-weight': 'bold',
              'text-align': 'center',
            }"
            style="width: 100%"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              property="fullname"
              align="center"
              label="居间人全称"
            >
            </el-table-column>
            <el-table-column
              property="simplename"
              align="center"
              label="居间人简称"
            >
            </el-table-column>
            <el-table-column
              property="buzzdept"
              align="center"
              label="营业部名称"
            >
            </el-table-column>
            <el-table-column
              property="deptphone"
              align="center"
              label="营业部电话"
            >
            </el-table-column>
          </el-table>
        </div>
        <div>
          <h3 class="text_title">{{ pageData.title }}</h3>
          <p class="text_time">
            发布时间：<span>{{ pageData.publishTime }}</span>
          </p>
          <div class="article_details" v-html="pageData.content"></div>
          <div style="width: 100%; height: 1px; background: #e5e5e5"></div>
          <div class="next_box">
            <div class="article_next">
              <div
                class="article_next_item"
                v-if="next_list.b1"
                @click="handle_next(next_list.b1)"
              >
                上一篇：{{ next_list.b1 ? next_list.b1.title : next_list.b1 }}
              </div>
              <div
                class="article_next_item"
                v-if="next_list.b2"
                @click="handle_next(next_list.b2)"
              >
                下一篇：{{ next_list.b2 ? next_list.b2.title : next_list.b2 }}
              </div>
            </div>
            <div class="article_return" @click="handle_article_return">
              返回
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      is_show_menu: this.$route.query.id,
      columnName: this.$route.query.name,
      pageData: {},
      search_input: '',
      Data: {},
      next_list: {},
      tableData: [],
      is: false
    };
  },
  watch: {
    $route: "getId",
  },
  async mounted() {
    await this.handle_details();
    await this.handle_scrollTop();
  },
  methods: {
    handle_search() {
      if (this.search_input == '') {
        this.$message.error("请输入关键词搜索");
        return
      }
      this.is = true
      this.$axios({
        method: "get",
        url: `/api/web/mediator/list?name=${this.search_input}&start=0&limit=999`,
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
      }).then(res => {
        this.tableData = res.data
      })
      // data
    },
    input_focus() {
      this.$('.search_twig').css({ border: '1px solid #c21d14' })
    },
    input_blur() {
      this.$('.search_twig').css({ border: '1px solid #DADADB' })
    },
    handle_article_return() {
      window.history.go(-1);
    },

    handle_scrollTop() {
      this.$("html,body").animate({ scrollTop: 0 }, 500); //回到顶端
    },
    handle_details() {
      this.$axios({
        method: "get",
        url: `/api/web/cms/view?id=${this.$route.query.my_id}`,
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
      }).then((res) => {
        this.pageData = res.data.data;
      });
      this.$axios({
        method: "get",
        url: `/api/web/cms/neighbor?cmsArticleId=${this.$route.query.my_id}`,
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
      }).then((res) => {
        this.next_list = {
          b1: res.data.data[0],
          b2: res.data.data[1],
        }
      });

    },
  },
};
</script>
<style scoped>
.search_twig {
  width: 450px;
  height: 40px;
  background: #f5f5f5;
  border: 1px solid #dadadb;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin: 0 auto 45px auto;
}
.search_button {
  width: 132px;
  text-align: center;
  background: #c21d14;
  color: #fff;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  cursor: pointer;
}
.download_item1 {
  width: 50px;
  height: 50px;
  background: #c6271e;
  display: flex;
  align-items: center;
  justify-content: center;
}
.download_item2 {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.download_box {
  margin-bottom: 15px;
  padding-right: 10px;
  width: 440px;
  height: 50px;
  background: #f7f7f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.article_next_item {
  cursor: pointer;
}
.article_return {
  width: 92px;
  height: 40px;
  line-height: 40px;
  background: #c6271e;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}
.next_box {
  margin-top: 21px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.article_next {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 37px;
}
.text_title {
  text-align: center;
  font-size: 32px;
  line-height: 45px;
  margin: 40px 0 20px 0;
}
.text_time {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  text-align: center;
}
.article_details {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 38px;
  padding: 30px 0;
}
/* .article_details img{
  width: 100%;
} */
.box_title {
  position: absolute;
  top: 84px;
  left: 23px;
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
.article_list_right_tab {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 66px;
}
.article_list_right_title1 {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 66px;
  border-bottom: #c21d14 3px solid;
}
.article_list_left {
  margin-right: 40px;
}
.article_list_right {
  flex: 1;
}
.article_list_right_top {
  border-bottom: #e5e5e5 1px solid;
  display: flex;
  justify-content: space-between;
}
.dian {
  display: inline-block;
  width: 4px;
  height: 4px;
  background: #d3d3d3;
  border-radius: 50%;
  margin-right: 10px;
  position: absolute;
  left: 9%;
  top: 45%;
}
.article_list_title {
  width: 280px;
  height: 61px;
  background: #c21d14;
  border-radius: 10px 10px 0px 0px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: left;
  padding-left: 29px;
  line-height: 61px;
}
.article_list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  width: 1200px;
  margin: 20px auto 94px auto;
}
.img_title {
  width: 100%;
  background: #ccc;
}
.article_list_box {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}
</style>
